import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(theme => ({
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  submitButton: {
    backgroundColor: '#141ad0 !important',
    marginLeft: '5px !important',
    fontSize: '12px !important',
    color: '#ffffff !important',
  },
}));

const DateRangePicker = ({
  selectedStartDate,
  selectedEndDate,
  onDateRangeSelect,
  closeDrawer,
  id,
  className,
}) => {
  const classes = useStyles();
  const startRef = useRef(null);
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [selectionComplete, toggleSelectionComplete] = useState(false);

  const handleDateChange = date => {
    if (!selectionComplete && !startDate) {
      //on first date selection, set the start date

      setStartDate(date);
      return;
    }

    if (!selectionComplete && startDate && !endDate) {
      //on second date selection, set the end date

      setEndDate(date);
      toggleSelectionComplete(true);
      startRef.current.setOpen(false);

      //do stuff with date range

      return;
    }

    if (startDate && endDate) {
      //on third date selection, begin selection of a new date range
      //reset the start date and clear the end date.

      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }
  };

  const handleSelect = date => {
    //onChange is not fired if selecting same date - workaround to fire handleDateChange
    if (
      !selectionComplete &&
      startDate &&
      !endDate &&
      sameDay(date, startDate)
    ) {
      handleDateChange(date);
    }
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const handleSubmit = () => {
    if (onDateRangeSelect) {
      onDateRangeSelect(startDate, endDate);
    }
    closeDrawer();
  };

  return (
    <div
      className={`${classes.datePickerContainer} ${className || ''}`}
      id={id}
    >
      <div>
        <DatePicker
          ref={startRef}
          selected={startDate}
          onChange={handleDateChange}
          onSelect={handleSelect}
          startDate={startDate}
          endDate={endDate}
          showIcon
          selectsRange
          withPortal
          shouldCloseOnSelect={false}
          onClickOutside={() => startRef.current.setOpen(false)}
          focusOnShow={false}
          onFocus={e => {
            e.preventDefault();
            e.target.blur();
            startRef.current.setOpen(true);
          }}
          value={`${
            startDate ? moment(startDate).format('DD/MM/YYYY') : 'NA'
          } - ${endDate ? moment(endDate).format('DD/MM/YYYY') : 'NA'}`}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.submitButton}
        disabled={!startDate || !endDate}
      >
        Submit
      </Button>
    </div>
  );
};

export default DateRangePicker;
