import { isNullOrUndefined } from '../../util/object';
import { subYears } from 'date-fns';
/**
 * Given maximum two periodIds, validates and returns
 * an array having the first element the current period and the second the previous period
 * @param {String[]} periodIds
 * @param {String} givenPeriodId
 */
export const semanticPeriods = (periodIds, givenPeriodId, useLLY) => {
  const count = periodIds.length;
  if (!count || count > 3) {
    throw new Error(`Invalid number of periods sent ${count}.`);
  }
  const givenPeriod = givenPeriodId.replace(/\D/g, '').substr(0, 4);
  const prevPeriod = useLLY
    ? subYears(new Date(givenPeriod, 0, 1), 2).getFullYear()
    : subYears(new Date(givenPeriod, 0, 1), 1).getFullYear();
  let currentPeriod = periodIds.find(p => p.includes(givenPeriodId));
  let previousPeriod = periodIds.find(p =>
    p.replace(/\D/g, '').substr(0, 4).includes(prevPeriod)
  );
  return { currentPeriod, previousPeriod };
};

export const semanticPeriodsWithValidation = (
  periodsObject,
  useLLY,
  givenPeriodId
) => {
  try {
    if(givenPeriodId === "timeframe"){
      return {
        currentPeriod: "cy", previousPeriod: "ly"
      }
    }
    const { currentPeriod, previousPeriod } = semanticPeriods(
      Object.keys(periodsObject),
      givenPeriodId,
      useLLY
    );

    // the response was retrieved successfully but the current period is missing
    // note: empty object considered to be valid
    if (isNullOrUndefined(periodsObject[currentPeriod])) {
      throw new Error(
        `Received null or undefined for current period id ${givenPeriodId} in the response.`
      );
    }

    return { currentPeriod, previousPeriod };
  } catch (error) {
    console.log(error);
    return false;
  }
};
