import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectDrawer } from '../../components/Select';
import { usePreferencesManager } from '../user-preferences';

export const getPreferredMetric = (metrics, pm) => {
  const savedId = pm.get('fav_metric');
  if (!savedId && savedId !== 0) return null;

  return metrics.find(x => x.id === savedId || x.noTaxId === savedId);
};

const MetricsShell = ({ children, metrics: items }) => {
  const pm = usePreferencesManager();
  const { formatMessage: f } = useIntl();

  const [drawer, setDrawer] = useState(false);
  const [finalItems, setFinalItems] = useState([]);
  const [selected, setSelected] = useState(getPreferredMetric(items, pm));

  // `useState`'s initial value is computed only once (on mount)
  // therefore whenever the component gets passed new `items` (currency or taxes switches)
  // a new value needs to be computed
  useEffect(() => {
    const preferred = getPreferredMetric(items, pm);
    setSelected(preferred);
    const translatedItems = items.map(item => {
      return {
        ...item,
        label: f({ id: `app.${item.id}` }),
      };
    });
    setFinalItems(translatedItems);
    // eslint-disable-next-line
  }, [items, pm]);

  const toggleSelect = () => {
    setDrawer(true);
  };

  const handleSelect = item => {
    setSelected(item);
    pm.set('fav_metric', item.noTaxId || item.id);
    setDrawer(false);
  };

  return (
    finalItems && (
      <>
        {children(selected, toggleSelect)}
        <SelectDrawer
          open={drawer}
          title={f({ id: 'app.available_kpis' })}
          onClose={() => setDrawer(false)}
        >
          <Select
            items={finalItems}
            selected={selected}
            onSelect={handleSelect}
          />
        </SelectDrawer>
      </>
    )
  );
};

export default MetricsShell;
