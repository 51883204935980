import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import cx from 'clsx';
import Chartist from 'chartist';
import 'chartist/dist/chartist.css';

import { formatAbbreviatedNumber } from '../../format';

const styles = theme => ({
  root: {
    height: 195,
    '& .ct-series-a .ct-bar': {
      stroke: theme.palette.primary.main,
      strokeWidth: 28,
    },
    '& .ct-series-b .ct-bar': {
      stroke: theme.palette.secondary.main,
      strokeWidth: 28,
    },
    '& .ct-label': {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '120%',
      letterSpacing: '0.04em',
      opacity: 0.65,
      color: 'inherit',
    },
    '& .ct-label.ct-vertical': {
      opacity: 0.3,
    },
    '& .ct-label.ct-vertical.ct-start': {
      justifyContent: 'space-around',
    },
  },
});

const BarChart = ({ classes, className, data }) => {
  const chartistRef = useRef();
  const [chartId] = useState(
    () => `chart-${Math.random().toString(36).substr(2, 9)}`
  );

  useEffect(() => {
    if (chartistRef.current) {
      chartistRef.current.update(data);
    } else {
      chartistRef.current = new Chartist.Bar(`#${chartId}`, data, {
        axisX: {
          offset: 19,
          showGrid: false,
        },
        axisY: {
          offset: 15,
          showGrid: false,
          labelInterpolationFnc: value => formatAbbreviatedNumber(value),
          onlyInteger: true,
          scaleMinSpace: 24,
        },
        seriesBarDistance: 28,
        chartPadding: {
          top: 15,
          right: 0,
          bottom: 0,
          left: 10,
        },
      });
    }
  }, [data, classes.root, chartId]);

  return <div id={chartId} className={cx(classes.root, className)} />;
};

export default withStyles(styles, { name: 'LriBarChart' })(BarChart);
