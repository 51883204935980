export const selectedInterval = [new Date(2019, 2, 28), new Date(2019, 3, 3)];

export const metrics = [
  {
    label: 'Revenue',
    format: 'money',
    value: { currency_code: 'GBP', value: '392123.45' },
    dpy: -0.24,
    tags: ['favorite'],
  },
  {
    label: 'SOT',
    format: 'money',
    value: { currency_code: 'GBP', value: '10.63' },
    dpy: 0.18,
    tags: ['favorite'],
  },
  {
    label: 'Traffic',
    format: 'number',
    value: 36910,
    dpy: -0.35,
    tags: ['favorite'],
  },
  {
    label: 'UPT',
    format: 'number',
    value: 1.33,
    dpy: -0.03,
  },
  {
    label: 'Footfall Demand',
    format: 'number',
    value: 31,
    dpy: -0.05,
  },
  {
    label: 'Transactions',
    format: 'number',
    value: 5398,
    dpy: -0.28,
  },
  {
    label: 'AUR',
    format: 'number',
    value: 54.86,
    dpy: 0.09,
  },
  {
    label: 'ATV',
    format: 'money',
    value: { currency_code: 'GBP', value: '72.70' },
    dpy: 0.06,
  },
  {
    label: 'Retail Sales Return',
    format: 'number',
    value: 2,
    dpy: 0.03,
  },
];

export const listOfKPIs = ['retail_sales_net_value', 'aos_sales', 'aos_percentage'];
